<template>
  <div class="draft-home">
    <el-card>
      <div class="draft-name">草稿箱({{this.articleList.length}})</div>
      <el-divider></el-divider>
      <div v-for="article in articleList" :key="article.id">
        <div class="draft-title">{{article.title}}</div>
        <div class="draft-box">
          <div class="draft-time">{{article.createTime}}</div>
          <div class="draft-edit">
            <el-button @click="toArticle(article.id)" size="small" type="primary" plain>编辑</el-button>
            <el-button @click="removeArticle(article.id)" size="small" type="danger" plain>删除</el-button>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articleList: []
    };
  },
  created() {
    this.getArticleDraft();
  },
  methods: {
    async getArticleDraft() {
      const { data: res } = await this.$api.getArticleDraft();
      this.articleList = res.data;
    },
    // 跳转至文章详情页面
    toArticle(id) {
      this.$router.push({ name: "articleEdit", params: { articleId: id } });
    },
    // 删除文章
    async removeArticle(id) {
      const result = await this.$confirm("是否删除该文章?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteArticleInfo(id);
      this.getArticleDraft();
    }
  }
};
</script>

<style lang="less" scoped>
.draft-home {
  // height: 100%;
  width: 900px;
  // background-color: #ceee;
  .draft-name {
    height: 60px;
    // background-color: #b4e4ee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .draft-title {
    font-weight: 600;
  }
  .draft-box {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
}
</style>